<template>
  <div>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: '予約管理',
      apis: {
        list: {
          endpoint: this.$consts.API.HOTEL.BOOKING_LIST,
        },
      },
      actions: {
        others: [
          {
            label: this.$t('common.view'),
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.HOTEL.BOOKING_EDIT,
                params: {id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default text-center">` + this.$t('common.view') + `</div>`,
          },
          {
            label: '履歴',
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.HOTEL.BOOKING_HISTORY_LIST,
                params: {id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default text-center">履歴</div>`,
          },
        ]
      },
      tools: {
        search: {
          reset: true,
          filters: [
            {
              name: 'start_point_id',
              type: 'equal',
              label: this.$t('common.start point'),
              uiType: 'select',
            },
            {
              name: 'status',
              type: 'equal',
              label: this.$t('booking.status'),
              uiType: 'select',
              options: this.$consts.BOOKING.STATUS_SELECT
            },
            {
              name: 'checkin_date',
              type: 'greater_or_equal',
              label: this.$t('booking.checkin_date'),
              from: true,
              boundaryClass: 'col-lg-6',
              uiType: 'datepicker_from',
            },
            {
              name: 'checkin_date',
              type: 'less_or_equal',
            },
            {
              name: 'c_name',
              type: 'like',
              label: this.$t('booking.c_name'),
              uiType: 'input',
            },
            {
              name: 'c_email',
              type: 'like',
              label: this.$t('common.mail address'),
              uiType: 'input',
            },
            {
              name: 'booking_code',
              type: 'like',
              label: '予約コード',
              uiType: 'input',
            },
            {
              name: 'c_phone',
              type: 'like',
              inputType: 'number-length:11',
              label: this.$t('booking.c_phone_list'),
              uiType: 'input',
            },
            {
              name: 'booking_date',
              type: 'greater_or_equal',
              label: this.$t('booking.booking_date'),
              from: true,
              boundaryClass: 'col-lg-6',
              uiType: 'datepicker_from',
            },
            {
              name: 'booking_date',
              type: 'less_or_equal',
            },
          ]
        }
      },
      listHotel: [],
      entrySelectedDelete: {},
      entryToSave: {active: 1},
      entryToEdit: {},
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {name: 'booking_code', label: this.$t('booking.booking_code'), sortable: true, class: "min-w-100"},
        {name: 'hotel_name', label: this.$t('hotel.hotel_name'), sortable: false, class: "min-w-80"},
        {name: 'start_point_name', label: this.$t('common.start point'), sortable: false, class: "min-w-80"},
        {name: 'c_name', label: this.$t('booking.c_name'), sortable: false, class: "min-w-100"},
        {
          name: 'c_phone',
          sortable: false,
          class: "min-w-110",
          label: this.$t('booking.c_phone_list'),
          computed: entry => {
            let cPhoneMobile = (entry.c_phone_mobile ? entry.c_phone_mobile + '（携帯）' : '')
            let cPhoneHome = (entry.c_phone_home ? entry.c_phone_home + '（自宅）' : '')
            if (cPhoneMobile.length || cPhoneHome.length) {
              return cPhoneMobile + ((cPhoneHome && cPhoneMobile) ? ' - ' : '') + cPhoneHome;
            }
          }
        },
        {name: 'c_email', label: this.$t('common.mail address'), sortable: false, class: "min-w-100"},
        {
          name: 'checkin_date',
          label: this.$t('booking.checkin_date'),
          sortable: false,
          class: "min-w-100",
          computed: entry => {
            return this.formatDate(entry.checkin_date)
          }
        },
        {
          name: 'booking_date',
          label: this.$t('booking.booking_date'),
          sortable: false,
          class: "min-w-100",
          computed: entry => {
            return this.formatDate(entry.booking_date)
          }
        },
        {
          name: 'status',
          label: this.$t('common.status'),
          class: "text-center",
          computedHtml: entry => {
            if (entry.status == this.$consts.BOOKING.STATUS_NEW) {
              return `<span class="alert alert-info">${this.$consts.BOOKING.STATUS_NEW_LABEL}</span>`
            } else if (entry.status == this.$consts.BOOKING.STATUS_ARRANGER) {
              return `<span class="alert alert-warning">${this.$consts.BOOKING.STATUS_ARRANGER_LABEL}</span>`
            } else if (entry.status == this.$consts.BOOKING.STATUS_UPDATE) {
              return `<span class="alert alert-warning">${this.$consts.BOOKING.STATUS_UPDATE_LABEL}</span>`
            } else if (entry.status == this.$consts.BOOKING.STATUS_CANCEL_NO_CHARGE) {
              return `<span class="alert alert-disable">${this.$consts.BOOKING.STATUS_CANCEL_NO_CHARGE_LABEL}</span>`
            } else if (entry.status == this.$consts.BOOKING.STATUS_CANCEL_CHARGE) {
              return `<span class="alert alert-disable">${this.$consts.BOOKING.STATUS_CANCEL_CHARGE_LABEL}</span>`
            } else if (entry.status == this.$consts.BOOKING.STATUS_OPERATED) {
              return `<span class="alert alert-success">${this.$consts.BOOKING.STATUS_OPERATED_LABEL}</span>`
            }
          },
          sortable: false
        },
      ]
    }
  },
  components: {BasicTable},
  mounted() {
    this.$request.get(this.$consts.API.HOTEL.BOOKING_RELATE_DATA, null, {noLoadingEffect: true}).then(res => {
      if (!res.hasErrors()) {
        const {hotels, start_points} = res.data;
        this.setOptionToolFilter(this.tools, 'hotel_id', 'equal', hotels);
        this.setOptionToolFilter(this.tools, 'start_point_id', 'equal', start_points);
      }
    })
  },
  methods: {}
}
</script>
